import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import { connect, type ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import translit from 'translitit-cyrillic-russian-to-latin';

import { getComments, sendComment } from '../../../actions/comments';
import { eraseStateProp } from '../../../actions/dashboard';
import { COMMENT_TYPE_OPTIONS, DN_MAP_PALMER, PLAN_LANG_OPTION_MAP } from '../../../common/constants';
import { Status } from '../../../common/courses';
import { deployedUSA } from '../../../common/utils';
import { PLAN_LINK_V2, PLAN_LINK_V2_RU } from '../../../config';
import type { TComment } from '../../../reducers/login';
import type { RootState } from '../../../store';
import { FormatDate } from "../../common/FormatDate";
import Loader from '../../common/loadingInProgress';
import { formatPersonName } from "../../common/PersonName";
import { Portlet, PortletTitle } from '../../ui/portlet';
import PatientAskQuestion from "../patient_ask_question";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    intl: state.intl,
    comments: state.comments,
    patient: state.patient,
    token: state.token,
  };
};

const mapDispatchToProps = {
  getData: getComments,
  sendData: sendComment,
  eraseStateProp,
};

function TranslitFromCyrillic({ id, locale }: { id: string; locale: string; }) {
  if (locale !== 'ru') {
    return <span>{translit(id)}</span>;
  } else {
    return <span>{id}</span>;
  }
}

type PatientCommentsSmileProps = PropsFromRedux & RouteComponentProps<{ patient_id: string; }>;

type PatientCommentsSmileState = {
  comments: TComment[];
  question: unknown[];
  canSubmit: boolean;
  showLoader: boolean;
};

class PatientCommentsSmile extends Component<PatientCommentsSmileProps, PatientCommentsSmileState> {
  comment: HTMLTextAreaElement | null = null;

  constructor(props: PatientCommentsSmileProps) {
    super(props);
    this.state = {
      comments: [],
      question: [],
      canSubmit: true,
      showLoader: false
    }
    this.sendComment = this.sendComment.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement('body');
    const { patient_id } = this.props.match.params;
    this.props.getData(patient_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PatientCommentsSmileProps) {
    if (Object.keys(nextProps.comments).length === 0) {
      this.setState({ comments:[] });
    } else {
      this.setState({ comments: nextProps.comments });
      this.setState({ canSubmit:true });
      this.setState({ showLoader:false });
    }
  }

  componentWillUnmount() {
    this.props.eraseStateProp('comments');
  }

  sendComment() {
    if (this.comment && this.comment.value !== "") {
      if (this.comment.value != "") {
        this.props.sendData(this.props.match.params.patient_id, this.comment.value);
      }
      this.comment.value = '';
      this.setState({ canSubmit: false });
      this.setState({ showLoader: true });
    }
  }

  open3dPlan() {
    const { user } = this.props;
    let langLocale = this.props.intl.locale;
    if (deployedUSA() === true) {
      langLocale = PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] || PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(PLAN_LINK_V2(this.props.patient.patient_id, this.props.token, langLocale))
    } else {
      window.open(PLAN_LINK_V2_RU(this.props.patient.patient_id, this.props.token, langLocale))
    }
  }

  renderCommentHint(comment: TComment) {
    if (comment && comment.type && Object.keys(COMMENT_TYPE_OPTIONS).includes(String(comment.type))) {
      const { type } = comment;
      return (
        <span style={{ color: COMMENT_TYPE_OPTIONS[type].color, fontWeight: "600", fontSize: "12px"}}>
          <FormattedMessage id={COMMENT_TYPE_OPTIONS[type].translation} />
        </span>
      )
    }
    return null;
  }

  render() {
    const hideCommentInput = this.props.patient && this.props.patient.status == Status._3D_PLAN_APPROVAL
    if (this.props.comments) {
      return (
        <Portlet id="comment-block">
          <PortletTitle as="h2" iconClassName="icon-speech" id="comment-title">
            <FormattedMessage id="pat.comment.title" />
          </PortletTitle>

          <div className="portlet-body form">
            {!hideCommentInput ? (
                <div className="form-body">
                  <div className="alert alert-danger display-hide">
                    <button className="close" data-close="alert" />
                    <span id="error-msg" />
                  </div>
                  <div className="form-group" id="comment-form-non-mobile">
                    <label
                      htmlFor="comment-form-non-mobile-input"
                      className="control-label"
                      id="comment-form-non-mobile-label"
                    >
                      <FormattedMessage id="pat.comment.button" />
                    </label>
                    <textarea
                      data-matomo-mask
                      className="form-control"
                      id="comment-form-non-mobile-input"
                      rows={5}
                      name="comment"
                      defaultValue=""
                      ref={(input) => this.comment = input}
                    />
                  </div>
                </div>
              ) : (
                  <div className="alert alert-warning" id="comment-form-mobile">
                    <span style={{ color: "black" }} id="comment-form-mobile-warning">
                      <FormattedMessage id="comment.message.on.plan.approve" />
                    </span>.&nbsp;
                    {this.props.token && this.props.patient.media['3d-plan'].response ? (
                        <button
                          id="comment-form-mobile-plan-button"
                          onClick={() => this.open3dPlan()}
                          style={{
                            color: '#008996',
                            backgroundColor: "transparent",
                            border: "none",
                            padding: 0 ,
                          }}
                        >
                          <u><FormattedMessage id="pat.plan.button"/></u>
                        </button>
                    ) : null}
                  </div>
            )}
              <div className="form-actions">
                {!hideCommentInput ? (
                    <button
                      type="submit"
                      className="btn green"
                      id="add-comment-btnnn comment-button-add-comment"
                      onClick={() => this.sendComment()}
                      disabled={!this.state.canSubmit}
                      style={{ margin: '10px' }}
                    >
                      <FormattedMessage id="pat.comment.button" />
                    </button>
                  ) : null
                }
                {this.props.patient.status != Status._3D_PLAN_APPROVAL ? <PatientAskQuestion/> : false}
                {this.state.showLoader ? <Loader/> : null}
              </div>
          </div>

          <div className="portlet-body">
            <div className="timeline">
              {this.state.comments.map((c, i) =>
                <div key={i} className="timeline-item" id="comment-body">
                  <div className="timeline-badge">
                    <div className="timeline-icon">
                      { this.props.user.account_id == c.account_id ? (<i className="icon-user font-red-haze" />) : (<i className="icon-user font-purple" />)}
                    </div>
                  </div>
                  <div className="timeline-body">
                    <div className="timeline-body-arrow">
                    </div>
                    <div className="timeline-body-head">
                      <div className="timeline-body-head-caption">
                        <span data-matomo-mask data-hj-suppress className="timeline-body-title font-blue-madison" id="comment-block-authon">
                          {c.account_id == this.props.user.account_id
                             ? <TranslitFromCyrillic id={formatPersonName({ person: { ...c.commenter, account_id: c.account_id }, user: this.props.user })} locale={this.props.intl.locale} />
                             : c.commenter.external === true
                                ? c.commenter.full_name
                                : <TranslitFromCyrillic
                                    id={formatPersonName({ person: { ...c.commenter, account_id: c.account_id }, user: this.props.user })}
                                    locale={this.props.intl.locale}
                                  />
                          }
                        </span>
                        &nbsp;
                        <FormatDate className="timeline-body-time font-grey-cascade" value={c.timestamp} date />
                      </div>
                      <div className="timeline-body-head-actions">
                        {this.renderCommentHint(c)}
                      </div>
                    </div>
                    <div className="timeline-body-content">
                      <span
                        data-matomo-mask
                        data-hj-suppress
                        style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                        className="font-grey-cascade"
                        id="comment-block-content"
                      >
                        {c.comment}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Portlet>
      )
    } else {
      return (
        <div>loading...</div>
      )
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientCommentsSmile);
