import type { IntlConfig } from "react-intl";
import type { TClinic, TPatient } from "./reducers/dashboard";
import type { TEvent } from "./reducers/events";
import type { TUser } from "./reducers/login";
import type { TOffer } from "./reducers/offer";

const API_ = process.env.API;

export const API_LOGIN          = API_ + '/authenticate/login'
export const API_OFFER = `${API_}/offer`
export const API_PATIENT_OFFERS_PUBLIC_GET_STATIC =
  (offer_id: TOffer["offer_id"], patient_id: TPatient["patient_id"], token: string) =>
    `${API_}/offers/patient/public/${offer_id}?patient_id=${patient_id}&token=${token}`;
export const API_DOCTOR_OFFERS_PUBLIC_GET_STATIC =
  (offer_id: TOffer["offer_id"]) => `${API_}/offers/doctor/public/${offer_id}`;
export const API_DOCTOR_OFFERS_PRIVATE_STATIC = `${API_}/offers/doctor/private`;
export const API_PATIENT_OFFERS_LAST_STATIC = (patient_id: string, token: string) =>
    `${API_}/offers/patient/last?patient_id=${patient_id}&token=${token}`;
export const API_REMINDER        = API_ + '/authenticate/reminder'
export const API_RECOVERY        = API_ + '/authenticate/recovery'
export const API_RECOVERY_RESTORE      = API_ + '/authenticate/recovery/restore'
export const API_WHOAMI         = API_ + '/authenticate/whoami'
export const API_LOGOUT         = API_ + '/authenticate/logout'
export const API_TOKEN         = API_ + '/authenticate/token'
export const API_GET_CLINIC = (clinic_id: TClinic["clinic_id"]) => `${API_}/doctor/clinics/${clinic_id}`;
export const API_UNLINK_CLINIC = (clinic_id: TClinic["clinic_id"]) => `${API_}/doctor/clinics/${clinic_id}`;
export const API_POST_CLINIC    = API_ + '/doctor/clinics'
export const API_CLINICS_LIST   = API_ + '/doctor/clinics'
export const API_PATIENTS_LIST  = API_ + '/patients'
export const API_DOCTORS_TASKS  = API_ + '/patients/tasks'
export const API_DOCTOR_PAYMENTS  = API_ + '/doctor/payments'
export const API_DOCTOR_PAYMENT_ACCOUNT  = API_ + '/doctor/payments/account'
export const API_PATIENT_IMAGE  = (patient_id, semantic)  => `${API_}/doctor/patients/${patient_id}/images/${semantic}`
export const API_GET_PATIENT = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}`;
export const API_PUT_PATIENT = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}`;
export const API_POST_PATIENT   = API_ + '/doctor/patients'
export const API_PUT_UNCOMPLETE_PATIENT = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}`;
export const API_SUBMIT_PATIENT = (patient_id) => `${API_}/doctor/patients/submit/${patient_id}`;
export const API_GET_PATIENT_COMMENTS = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/comments`;
export const API_POST_PATIENT_COMMENTS = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/comments`;
export const API_POST_PATIENT_QUESTIONS = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/questions`;
export const API_POST_NEW_MIDTERM_CORRECTION = (patient_id: TPatient["patient_id"]) => `${API_}/patients/${patient_id}/corrections`;
export const API_POST_STATUS_END = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/status/end`;
export const API_POST_STATUS_RETAINERS = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/retainers`;
export const API_POST_ADDITIONAL_ALIGNERS = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/aligners/additional`;
export const API_APPROVE_PLAN = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/status/approve`;
export const API_REWORK_PLAN = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/status/rework`;
export const API_EDIT_DOCTOR    = API_ + '/doctor'
export const API_INVOICE = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/invoice`;
export const API_DOCTOR_PAYMENT_INVOICE_BATCH  = `${API_}/doctor/payments/invoices/batch`
export const API_PATIENT_PAYMENT  = (patient_id)    => `${API_}/patients/payments/${patient_id}`
export const API_PUBLIC_LINK  = (patient_id)    => `${API_}/doctor/patients/${patient_id}/plan`
export const API_LAST_NEWS  = `${API_}/news`;
export const API_DELIVERIES = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/deliveries`;
export const API_DELIVERY_SHIPMENT = `${API_}/delivery/shipment`;
export const API_DELIVERY_PICKUP = `${API_}/delivery/pickup`;
export const API_DELIVERY_PICKUP_BY_PHONE = `${API_}/delivery/pickup/by/phone`
export const API_AUTHENTICATE_PATIENT = `${API_}/patients/authenticate`
export const API_PATIENTS_OFFER = `${API_}/patients/offers`
export const API_MANUALS_GET = `${API_}/manuals`
export const API_COURSE_INSTALLMENT_GET = `${API_}/courses`
export const API_MANUALS_GET_STATIC = (file_type, manual_id) => `${API_}/manuals/${file_type}/${manual_id}`
export const API_STATIC_GET = (filename: string) => `${API_}/files/${filename}`
export const API_EXTRA_SERVICES = (patient_id: TPatient["patient_id"]) => `${API_}/doctor/patients/${patient_id}/services`;
export const API_3SHAPE_AUTH  =  `${API_}/3shape/authorize`
export const API_3SHAPE_PATIENT_CASES  = (patient_id) =>  `${API_}/3shape/${patient_id}/cases`
export const API_3SHAPE_CASE  = (patient_id, case_id) =>  `${API_}/3shape/${patient_id}/cases/${case_id}`
export const API_3SHAPE_ATTACHES_UPDATE  = (patient_id) =>  `${API_}/3shape/${patient_id}/cases/attachments/status`
export const API_MEDIT_AUTH = `${API_}/integrations/medit/authorize`
export const API_MEDIT_PATIENT_CASES  = `${API_}/integrations/medit/cases`
export const API_MEDIT_CASE  = (case_id) =>  `${API_}/integrations/medit/cases/${case_id}`
export const API_MEDIT_PATIENTS = `${API_}/search/patients`
export const API_POST_PATIENT_MEDIT  = (case_id) => `${API_}/integrations/medit/cases/${case_id}`
export const API_POST_PATIENT_CONNECT  = (case_id, patient_id) => `${API_}/integrations/medit/cases/${case_id}/connect/${patient_id}`
export const API_POST_PATIENT_DISCONNECT  = (patient_id) => `${API_}/integrations/medit/cases/patients/${patient_id}`
export const API_POST_UPLOAD_FILE = (file_id) => `${API_}/integrations/medit/cases/${file_id}/files`
export const API_GET_BONUSES = (doctor_id: TUser["account_id"]) => `${API_}/doctors/${doctor_id}/bonuses`;
export const API_BONUS_PATIENT = (doctor_id: TUser["account_id"], patient_id: TPatient["patient_id"]) => `${API_}/doctors/${doctor_id}/bonuses/${patient_id}`;
export const API_SHIPMENTS = `${API_}/doctor/shipments`;
export const API_MEETINGS = `${API_}/meetings`;

export const API_EVENTS_PAYMENTS = `${API_}/events/payments`;
export const API_EVENT = (event_id: TEvent["id"]) => `${API_}/events/${event_id}`;
export const API_EVENT_ENROLL_FREE = (event_id: TEvent["id"]) => `${API_}/events/${event_id}/enroll`;
export const API_EVENTS_FEEDBACK = (event_id: TEvent["id"]) => `${API_}/events/feedback/${event_id}`;
export const API_BANNERS = `${API_}/banners`;
export const API_NOTIFICATIONS = `${API_}/notifications`;
export const API_NOTIFICATIONS_CLERK_INVOICE = `${API_}/notifications/clerk/invoice`;

export const PLAN_LINK_V2 = (patient_id: TPatient["patient_id"], token: string, lang: IntlConfig["locale"]) =>
  `//${process.env.PLAN3D_API}/show-${patient_id}-secure-${token}-lang-${lang}-version=2`;

export const PLAN_LINK_V2_RU = (patient_id: TPatient["patient_id"], token: string, lang: IntlConfig["locale"]) =>
  `//${process.env.PLAN3D_API_RU}/show-${patient_id}-secure-${token}-lang-${lang}-version=2`;
