import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { getFormValues, isDirty } from "redux-form";

import { eraseStateProp } from '../../actions/dashboard';
import { courseSelectedAction,getCourseInstallment } from '../../actions/get_course_installment';
import { getToken } from '../../actions/get_token';
import { addInstructions, createInstructionsSuccess } from '../../actions/patient_new_instructions';
import { createAndSubmitPatient, createPatient, patientIsSavingSuccess } from '../../actions/post_patient';
import { Payer, RxType } from '../../common/constants';
import { isChildrenCourse, isRetainersCourse, isTeenCourse } from '../../common/courses';
import { convertToJSONFullRx } from "../../common/instructions";
import remoteLog from '../../common/logging';
import { deployedRussia, deployedUSA } from '../../common/utils';
import { setDocumentTitle } from '../../hooks/use-document-title';
import type { TCourse } from '../../reducers/dashboard';
import type { RootState } from '../../store';
import Loader from '../common/loadingInProgress';
import { scrollTo } from "../common/ScrollToElement/scrollTo";
import { Layout } from '../ui/layout';
import { Portlet } from '../ui/portlet';
import validators from '../validation/ValidationRules';
import LinksCT from "./addLinks";
import PatientNewDoctorInfo from './patient_new/patient_new_doctor_info';
import PatientNewInstructionsCourse from './patient_new/patient_new_instructions_course';
import { PatientNewInstructionsFiles } from './patient_new/patient_new_instructions_files';
import PatientNewInstructionsImages from './patient_new/patient_new_instructions_images';
import PatientNewPatientInfo from './patient_new/patient_new_patient_info';
import PatientNewPayment from './patient_new/patient_new_payment';
import PatientUpdateInstructionsUploadCt from './patient_update/patient_update_instructions_upload_ct';
import RecipeFormPredict from "./predict_recipe_redux_form";
import RecipeFormShortRX from './recipe_redux_form_short_rx';
import RouteLeavingGuard from './route_leaving_guard';
import RecipeFormSmile from "./smile_recipe_redux_form";

const requiredFields = [
  'validation-clinic_id',
  'validation-payer_id',
  'validation-payment_method_id',
  'validation-payment_option_id',
  'patient-block-body',
  'validation-email',
  'validation-course_id',
  'validation-condition',
  'validation-comment',
  'validation-material',
  'photo-protocol-block',
  'validation-arch',
  'validation-vertical_overlap_comment',
  'validation-midline'
];

const ScrollToField = (toId: string) => {
  scrollTo({ id: toId, duration: 1500 });
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    doctor_id: state.user.account_id,
    patient: state.patient,
    patientSaving: state.patientSaving,
    instructions: state.instructions,
    media: state.media,
    media_s3: state.media_s3,
    token: state.token,
    lang: state.intl,
    clinics: state.clinics,
    courseInstallment: state.courseInstallment,
    courseSelected: state.courseSelectedReducer,
    formValues: getFormValues('correction')(state),
    links: getFormValues('links')(state),
    dirtyCorrection: isDirty('correction')(state),
    dirtyLinks: isDirty('links')(state),
  };
};

const mapDispatchToProps = {
  createAndSubmitPatient: createAndSubmitPatient,
  createPatient: createPatient,
  patientIsSaving: patientIsSavingSuccess,
  initInstrucrions: createInstructionsSuccess,
  getToken,
  eraseStateProp,
  getCourseInstallment,
  courseSelected: courseSelectedAction,
  addInstruction: addInstructions,
};

type PatientNewProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

type PatientNewState = {
  showLoader: boolean;
  clicked: boolean;
  isDirty: boolean;
  currentPath: string | null;
  submit: boolean;
};

class PatientNew extends Component<PatientNewProps, PatientNewState> {
  constructor(props: PatientNewProps) {
    super(props);
    this.state = {
      showLoader: false,
      clicked: false,
      isDirty: false,
      currentPath: null,
      submit: false
    };
    this.submitButton = this.submitButton.bind(this);
    this.submitSaveButton = this.submitSaveButton.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  submitButton() {
    this.setState({ submit: true });
    if ($('#submit-pacient-btn').attr("disabled")) return;

    const rxTypeId = this.props.user.preferences.rx_type_id;
    const emptyFields: string[] = [];
    const links = [];
    const linksArray = this.props.links && this.props.links.links ? this.props.links.links : [];
    linksArray.forEach(el => links.push(...Object.values(el)));
    const mediaKeys = Object.keys(this.props.media);
    let mediaAttachments = mediaKeys.map(elm => this.props.media[elm].user_filename);
    const useScanClicked = (
      this.props.formValues.hasOwnProperty('material') &&
      this.props.formValues.material == 2
    );
    const vertical_overlap_comment = (
      this.props.formValues && this.props.formValues.hasOwnProperty('vertical_overlap') &&
      this.props.formValues && this.props.formValues.vertical_overlap === 1 ||
      this.props.formValues && this.props.formValues.vertical_overlap === 2
    );
    /*let scanUploaded = !!mediaAttachments.filter((elmm) => {
      if (elmm !== undefined && elmm != null) {
        const cond = elmm.includes('stl');
        return cond;
      }
      return false;
    }).length;*/
    const deepCbctCourseClicked = (
      (this.props.instructions.hasOwnProperty('course_id') && this.props.instructions.course_id == 5) ||
      (this.props.instructions.hasOwnProperty('deep_cbct') && this.props.instructions.deep_cbct == true)
    );
    let ctUploaded = this.props.media_s3.hasOwnProperty('files') && this.props.media_s3.files.length > 0;

    // Temporary disabling CT check and STL check
    ctUploaded = true;
    let scanUploaded = true;

    $('#instruction-files').css({
      color:
        !useScanClicked ? '#34495e' : scanUploaded ? '#34495e' : 'red'
    });

    $('label#upload-ct.form-label span').css({
      color:
        !deepCbctCourseClicked ? '#34495e' : ctUploaded ? '#34495e' : 'red'
    });

    if (this.props.clinics.length === 1 && !this.props.instructions.clinic_id) {
      this.props.instructions.clinic_id = parseInt(this.props.clinics[0].clinic_id);
    };

    if (
      (!['doctor_id', 'first_name', 'last_name'].filter((elm) => {
        const cond = (this.props.instructions.hasOwnProperty(elm) && this.props.instructions[elm] !== '');
        !cond ? emptyFields.push('patient-block-body') : null;
        $(`[name="${elm}"]`).parent().find('label').css({ color: (elm !== 'doctor_id' && !cond) ? 'red' : '#34495e' });
        return !cond;
      }).length
        *
        !['material'].filter((elm) => {
          const cond = (this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== null);
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        !['vertical_overlap_comment'].filter((elm) => {
          if (!vertical_overlap_comment) {
            return false;
          }
          const cond = (this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '');
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        !['email'].filter((elm) => {
          let cond = true;
          if (deployedRussia() && this.props.intl.locale == 'ru' && this.props.instructions.payer_id == Payer.PATIENT) {
            cond = this.props.instructions.hasOwnProperty(elm) && this.props.instructions[elm] && validators.validateEmail(this.props.instructions[elm]) === true;
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          }
          return !cond;
        }).length
        *
        !['payer_id'].filter((elm) => {
          let cond = true;
          if (deployedRussia() && this.props.intl.locale == 'ru') {
            cond = this.props.instructions.hasOwnProperty('payer_id') && [1, 2, 3, '1', '2', '3'].includes(this.props.instructions['payer_id']);
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          }
          return !cond;
        }).length
        *
        !['payment_option_id'].filter((elm) => {
          let cond = (this.props.instructions.hasOwnProperty(elm) && !isNaN(this.props.instructions[elm]) && this.props.instructions[elm] !== null);
          if (cond === false && deployedRussia()) {
            cond = (this.props.instructions.hasOwnProperty('sber_credit') && this.props.instructions.sber_credit == true);
          }
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        !['clinic_id', 'payment_method_id', 'course_id'].filter((elm) => {
          const cond = (this.props.instructions.hasOwnProperty(elm) && !isNaN(this.props.instructions[elm]) && this.props.instructions[elm] !== null);
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        !['condition'].filter((elm) => {
          let cond = true;
          if (deployedUSA() === true && rxTypeId === RxType.STANDARD) {
            cond = (this.props.instructions.hasOwnProperty(elm) && this.props.instructions[elm] !== '' && this.props.instructions[elm] !== null);
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
            return !cond;
          }
        }).length
        *
        !['comment'].filter((elm) => {
          let cond = true;
          if (deployedUSA() === true) {
            cond = (this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '');
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
            return !cond;
          }
        }).length
        *
        !links.filter((elm) => {
          const cond = (this.props.links && elm !== '');
          !cond ? emptyFields.push('validation-link') : null;
          return !cond;
        }).length
        *
        !['arch'].filter((elm) => {
          const cond = (this.props.formValues.hasOwnProperty(elm) && !isNaN(this.props.formValues[elm]) && this.props.formValues[elm] !== null);
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        !['midline'].filter((elm) => {
          if (isRetainersCourse(this.props.instructions.course_id)) {
            return false;
          }
          let cond = true;
          if (deployedUSA() === true && rxTypeId === RxType.STANDARD) {
            cond = (this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '' && this.props.formValues[elm] !== null);
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
            return !cond;
          }
        }).length
        *
        !(['course_id'] as const).filter((elm) => {
          const course_id = this.props.instructions[elm];

          if (course_id && (isChildrenCourse(course_id) || isTeenCourse(course_id))) {
            const payer_id = this.props.instructions["payer_id"];
            if (payer_id == Payer.PATIENT) {
              return (
                ["payer_first_name", "payer_last_name", "payer_patronymic"].filter((property) => {
                  const isValid =
                    this.props.instructions.hasOwnProperty(property) &&
                    this.props.instructions[property] !== "";
                  if (!isValid) {
                    emptyFields.push("patient-block-body");
                  }
                  $(`[name="${property}"]`)
                    .parent()
                    .find("label")
                    .css({ color: !isValid ? "red" : "#34495e" });
                  return !isValid;
                }).length > 0
              );
            }
          }

          return false;
        }).length
        *
        !['front_view', 'full_face_with_smile', 'full_face_without_smile', 'profile', 'lateral_view_left', 'lateral_view_right', 'occlusal_view_lower', 'occlusal_view_upper'].filter((elm) => {
          if (isRetainersCourse(this.props.instructions.course_id)) {
            return false;
          }
          const cond = (this.props.media[elm] && this.props.media[elm].md5);
          !cond ? emptyFields.push('photo-protocol-block') : null;
          $(`#${elm}`).parent().prev().prev().css({ color: !cond ? 'red' : '#34495e' });
          return !cond;
        }).length
        *
        //if use scan is clicked and uploaded files contains .stl
        // Boolean(useScanClicked ? scanUploaded : true)
        //unless deep cpct is clicked previous scenario is checked, after user clicks on deep cbct ctUploaded is verified
        Boolean((useScanClicked ? scanUploaded : true) && (deepCbctCourseClicked ? ctUploaded : true))
      ) &&
      (!this.props.instructions.bdate || (this.props.instructions.bdate && this.props.instructions.bdate !== 'Invalid date'))
    ) {
      this.props.instructions.prescription = convertToJSONFullRx(this.props.formValues);
      this.props.instructions.prescription.rx_type_id = rxTypeId;
      this.props.instructions.links = links;
      this.setState({ showLoader: true });
      this.setState({ clicked: true });
      this.props.createAndSubmitPatient(this.props.instructions, this.props.media, this.props.media_s3);
    } else {
      const scrollToFields: string[] = [];
      requiredFields.forEach(f => emptyFields.forEach(a => a == f ? scrollToFields.push(f) : null));
      ScrollToField(scrollToFields[0]);
      $('#submit-pacient-btn').removeClass('-error');
      setTimeout(() => {
        $('#submit-pacient-btn').addClass('-error');
      }, 0);
    }
  }

  submitSaveButton() {
    _paq.push(['trackEvent', 'New patient', 'Save as a draft']);
    this.setState({ submit: true });
    if ($('#save-pacient-btn').attr("disabled")) return;

    const rxTypeId = this.props.user.preferences.rx_type_id;
    const emptyFields: string[] = [];
    const links = [];
    const linksArray = this.props.links && this.props.links.links && this.props.links.links.length > 0 ? this.props.links.links : [];
    linksArray.forEach(el => links.push(...Object.values(el)));

    const vertical_overlap_comment = (
      this.props.formValues && this.props.formValues.hasOwnProperty('vertical_overlap') &&
      this.props.formValues && this.props.formValues.vertical_overlap === 1 ||
      this.props.formValues && this.props.formValues.vertical_overlap === 2
    );

    if (!['doctor_id', 'first_name', 'last_name'].filter((elm) => {
      const cond = (this.props.instructions.hasOwnProperty(elm) && this.props.instructions[elm] !== '');
      $(`[name="${elm}"]`).parent().find('label').css({ color: (elm !== 'doctor_id' && !cond) ? 'red' : '#34495e' });
      !cond ? emptyFields.push('patient-block-body') : null;
      return !cond;
    }).length
      *
      !['vertical_overlap_comment'].filter((elm) => {
        if (!vertical_overlap_comment) {
          return false;
        }
        const cond = (this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '');
        !cond ? emptyFields.push(`validation-${elm}`) : null;
        $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
        return !cond;
      }).length
      *
      !links.filter((elm) => {
        const cond = (this.props.links && elm !== '');
        emptyFields.push(elm);
        return !cond;
      }).length) {
      this.setState({ showLoader: true, isDirty: false });
      this.setState({ clicked: true });
      this.props.instructions.prescription = convertToJSONFullRx(this.props.formValues);
      this.props.instructions.prescription.rx_type_id = rxTypeId;
      this.props.instructions.links = links;
      this.props.createPatient(this.props.instructions, this.props.media, this.props.media_s3);
    } else {
      const scrollToFields: string[] = [];
      requiredFields.forEach(f => emptyFields.forEach(a => a == f ? scrollToFields.push(f) : null));
      ScrollToField(scrollToFields[0]);
      $('#save-pacient-btn').removeClass('-error');
      setTimeout(() => {
        $('#save-pacient-btn').addClass('-error');
      }, 0);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.initInstrucrions();
    this.props.getToken();
    this.props.eraseStateProp('patient');
    this.props.eraseStateProp('instructions');
    this.props.eraseStateProp('media');
    this.props.courseSelected(false);
    this.props.getCourseInstallment();
  }

  componentDidMount() {
    const { user } = this.props;

    _paq.push(['HeatmapSessionRecording::enable']);
    if (user && user.account_id) {
      _paq.push(['setUserId', user.account_id.toString()]);
    }
    this.setState({ currentPath: window.location.pathname })
    if (Object.keys(this.props.user).length > 0) {
      this.props.addInstruction({ 'rx_type_id': this.props.user.preferences.rx_type_id });
    }
    setDocumentTitle(this.props.intl.formatMessage({ id: "left.menu.add_patient" }));
  }

  UNSAFE_componentWillUpdate(nextProps: PatientNewProps) {
    if (nextProps.patientSaving === true) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp('patient');
      this.props.history.push('/pages/patients');
    }
  }

  componentDidUpdate(prevProps: PatientNewProps) {
    // NOTE: runs after you click on logout and user becomes an empty object, hence we
    // need to check for the existence of the preferences property before accessing rx_type_id
    if (prevProps.user !== this.props.user && this.props.user.preferences) {
      this.props.addInstruction({ 'rx_type_id': this.props.user.preferences.rx_type_id });
    }
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props
    const { isDirty, submit } = this.state;
    const formIsDirty = !submit && (isDirty || dirtyCorrection || dirtyLinks || Object.keys(media).length >= 1 || (media_s3.files && media_s3.files.length >= 1));
    if (formIsDirty) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_new');
  }

  setDirty(key: string, data) {
    const { instructions } = this.props;
    if (data !== "" || instructions[key] !== "") {
      this.setState({ isDirty: true })
    } else {
      this.setState({ isDirty: false })
    }
  }

  renderForm(course_id: TCourse["course_id"]) {
    return (
      deployedRussia() ? <RecipeFormSmile course_id={course_id} /> : <RecipeFormPredict course_id={course_id} />
    )
  }

  render() {
    if (Object.keys(this.props.user).length > 0) {
    const rxTypeId = this.props.user.preferences.rx_type_id;
    const course_id = this.props.instructions ? this.props.instructions.course_id : null;
    const { isDirty, currentPath, submit } = this.state;
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const formIsDirty = !submit && (isDirty || dirtyCorrection || dirtyLinks || Object.keys(media).length >= 1 || (media_s3.files && media_s3.files.length >= 1));

    return (
        <Layout>
          <Portlet as="main">
            <div className="portlet-title">
              <div className="col-md-8 col-sm-8 col-xs-8 caption">
                <i className="icon-book-open font-green" />
                <span className="caption-subject font-green bold uppercase">
                  <FormattedMessage id="left.menu.add_patient" />
                </span>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-4" style={{ padding: "8px 0" }}>
                <label><span className="required mob-title" aria-required="true">* </span>
                  <i className='mob-title'>
                    - <FormattedMessage id="requiredField" />
                  </i>
                  <span className='prescription_tooltip_show'>
                    <i style={{ fontSize: "12px", marginLeft: "6px" }} className='icon-question' />
                    <span style={{right: isMobile ? "-30px" : "", position: "absolute"}} className="prescription_tooltip">
                      <FormattedMessage id="required.fields.tooltip" />
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <RouteLeavingGuard
              when={formIsDirty}
              shouldBlockNavigation={() => formIsDirty}
              saveChanges={this.submitSaveButton}
              saveAsADraft={true}
              currentPath={currentPath}
            />
            <div className="portlet-body form">
              <div id="add-patient-form">
                <div className="form-body">
                  <div className="alert alert-danger display-hide">
                    <button className="close" data-close="alert" />
                    <span id="error-msg" />
                  </div>
                  <PatientNewDoctorInfo setDirty={this.setDirty} />
                  <PatientNewPayment setDirty={this.setDirty} />
                  <PatientNewPatientInfo setDirty={this.setDirty} />
                  <PatientNewInstructionsCourse setDirty={this.setDirty} rxTypeId={rxTypeId} />
                  <PatientNewInstructionsFiles />
                  {!isRetainersCourse(course_id) && <PatientUpdateInstructionsUploadCt setDirty={this.setDirty} />}
                  {!isRetainersCourse(course_id) && <LinksCT />}
                  {!isRetainersCourse(course_id) && <PatientNewInstructionsImages setDirty={this.setDirty} />}
                  {rxTypeId === RxType.STANDARD ? this.renderForm(course_id) : <RecipeFormShortRX course_id={course_id} />}

                  <input type="hidden" id="doctor-id" name="doctor_id" defaultValue={264} />
                </div>
                <div className="form-actions">
                  <button
                    type="button"
                    disabled={this.state.clicked}
                    style={{ margin: 2 }}
                    className="btn green"
                    id="submit-pacient-btn"
                    onClick={() => this.submitButton()}
                  >
                    <FormattedMessage id="BTN_SUBMIT_NEW" />
                  </button>
                  <button
                    type="button"
                    disabled={this.state.clicked}
                    style={{ margin: 2 }}
                    className="btn default"
                    id="save-pacient-btn"
                    onClick={() => this.submitSaveButton()}
                  >
                    <FormattedMessage id="BTN_SAVE_DRAFT" />
                  </button>
                </div>
                {this.state.showLoader && <Loader />}
              </div>
            </div>
          </Portlet>
        </Layout>
    );
    } else {
      return <Loader />
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNew));
