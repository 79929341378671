import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect,type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { getFormValues, isDirty } from "redux-form";

import { eraseStateProp } from "../../actions/dashboard";
import { getCourseInstallment } from "../../actions/get_course_installment";
import { getPatientId } from "../../actions/get_patient_id";
import {
  addInstructions,
  createInstructionsSuccess,
} from "../../actions/patient_new_instructions";
import {
  patientIsSavingSuccess,
  updateAndSubmitPatient,
  updatePatient,
} from "../../actions/post_patient";
import { Payer, RxType } from "../../common/constants";
import { is3DPROCourse, isChildrenCourse, isRetainersCourse, isTeenCourse, Status } from "../../common/courses";
import { convertToJSONFullRx } from "../../common/instructions";
import remoteLog from "../../common/logging";
import { getLastCorrection, isPatient } from "../../common/patient";
import { deployedRussia, deployedUSA } from "../../common/utils";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import PlanCorrectionPhoto from "../3d_plan_correction/3d_plan_correction_photo";
import Loader from "../common/loadingInProgress";
import { scrollTo } from "../common/ScrollToElement/scrollTo";
import { Layout } from "../ui/layout";
import { Portlet } from "../ui/portlet";
import validators from "../validation/ValidationRules";
import LinksCT from "./addLinks";
import ImpressionScanOptions from "./impression_scan_options";
import { FILE_TYPE_MEDIT } from "./patient_files";
import PatientUpdateInstructionsNewImages from "./patient_new/patient_new_instructions_images";
import PatientUpdateDoctorInfo from "./patient_update/patient_update_doctor_info";
import PatientUpdateInstructionsCourse from "./patient_update/patient_update_instructions_course";
import PatientUpdateInstructionsNewMedia from "./patient_update/patient_update_instructions_new_media";
import PatientUpdateInstructionsUploadCt from "./patient_update/patient_update_instructions_upload_ct";
import PatientUpdatePatientInfo from "./patient_update/patient_update_patient_info";
import PatientUpdatePayment from "./patient_update/patient_update_payment";
import RecipeFormPredict from "./predict_recipe_redux_form";
import RecipeFormShortRX from "./recipe_redux_form_short_rx";
import RouteLeavingGuard from "./route_leaving_guard";
import RecipeFormSmile from "./smile_recipe_redux_form";

const requiredFields = [
  "validation-clinic_id",
  "validation-payer_id",
  "validation-payment_method_id",
  "validation-payment_option_id",
  "patient-block-body",
  "validation-email",
  "validation-course_id",
  "validation-condition",
  "validation-comment",
  "validation-material",
  "validation-arch",
  "validation-vertical_overlap_comment",
  "validation-midline",
  "photo-protocol-block",
];

const ScrollToField = (toId: string) => {
  scrollTo({ id: toId, duration: 1500 });
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    doctor_id: state.user.account_id,
    patient: state.patient,
    patientSaving: state.patientSaving,
    instructions: state.instructions,
    media: state.media,
    media_s3: state.media_s3,
    formValues: getFormValues("correction")(state),
    links: getFormValues("links")(state),
    dirtyCorrection: isDirty("correction")(state),
    dirtyLinks: isDirty("links")(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAndSubmitPatient: (id, data) =>
      dispatch(updateAndSubmitPatient(id, data)),
    getData: (id) => dispatch(getPatientId(id)),
    patientIsSaving: (bool) => dispatch(patientIsSavingSuccess(bool)),
    initInstrucrions: () => dispatch(createInstructionsSuccess()),
    erasePatient: () => dispatch(eraseStateProp("patient")),
    eraseMedia: () => dispatch(eraseStateProp("media")),
    updatePatient: (...args) => dispatch(updatePatient(...args)),
    getCourseInstallment: () => dispatch(getCourseInstallment()),
    addInstruction: (data) => dispatch(addInstructions(data)),
  };
};

type PatientUpdateProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{ patient_id: string; }>;

type PatientUpdateState = {
  patient_id: string;
  showLoader: boolean;
  values: Record<string, unknown>;
  isDirty: boolean;
  currentPath: string | null;
  submit: boolean;
};

class PatientUpdate extends Component<PatientUpdateProps, PatientUpdateState> {
  constructor(props: PatientUpdateProps) {
    super(props);
    this.state = {
      patient_id: this.props.match.params.patient_id,
      showLoader: false,
      values: {},
      isDirty: false,
      currentPath: null,
      submit: false,
    };
    this.submitButton = this.submitButton.bind(this);
    this.submitSaveButton = this.submitSaveButton.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.fm = this.fm.bind(this);
    this.renderRx = this.renderRx.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_update');
  }

  submitButton() {
    const lastCorrection = getLastCorrection(this.props.patient);
    const prescription =
      this.props.patient && this.props.patient.course.correction
        ? this.props.patient.course.correction[
            this.props.patient.course.correction.length - 1
          ].prescription
        : {};
    if ($("#submit-pacient-btn").attr("disabled")) return;

    const course_id = this.props.instructions.course_id
      ? this.props.instructions.course_id
      : this.props.patient.course.course_id;
    /**For some old cases prescription can be nullable. NOTE: check prescription existence before trying to enter fields */
    const rxTypeId =
      this.props.patient &&
      this.props.patient.course.correction &&
      this.props.patient.course.correction[
        this.props.patient.course.correction.length - 1
      ].prescription
        ? this.props.patient.course.correction[
            this.props.patient.course.correction.length - 1
          ].prescription.rx_type_id
        : this.props.patient.rx_type_id;
    const emptyFields: string[] = [];
    const links = [];
    const linksArray =
      this.props.links && this.props.links.links ? this.props.links.links : [];
    linksArray.forEach((el) => links.push(...Object.values(el)));
    const vertical_overlap_comment =
      (this.props.formValues &&
        this.props.formValues.hasOwnProperty("vertical_overlap") &&
        this.props.formValues &&
        this.props.formValues.vertical_overlap === 1) ||
      (this.props.formValues && this.props.formValues.vertical_overlap === 2);
    let mediaKeys = [];
    if (this.props.media) {
      Object.keys(this.props.media).forEach((filename) => {
        mediaKeys.push(this.props.media[filename].user_filename);
      });
    }
    Object.keys(this.props.media);
    if (this.props.patient && this.props.patient.media) {
      if (this.props.patient.media.optional_media) {
        mediaKeys = [
          ...mediaKeys,
          ...this.props.patient.media.optional_media.map(
            (file) => file.linkto || ""
          ),
        ];
      }
    }
    const useScanClicked =
      (((this.props.formValues &&
        this.props.formValues.hasOwnProperty("material")) ||
        (prescription && prescription.hasOwnProperty("material"))) &&
        this.props.formValues &&
        this.props.formValues.material == 2) ||
      (prescription && prescription.material === 2);
    /*let scanUploaded = !!mediaKeys.filter((elmm) => {
      const cond = elmm.includes('stl');
      return cond;
    }).length*/
    const deepCbctCourseClicked =
      (this.props.instructions.hasOwnProperty("course_id") &&
        this.props.instructions.course_id == 5) ||
      (this.props.instructions.hasOwnProperty("deep_cbct") &&
        this.props.instructions.deep_cbct == true);
    let ctUploaded =
      (this.props.patient.hasOwnProperty("s3_media") &&
        this.props.patient.s3_media.length > 0) ||
      (this.props.media_s3.hasOwnProperty("files") &&
        this.props.media_s3.files.length > 0);

    // Temporary disabling CT check and STL check
    ctUploaded = true;
    let scanUploaded = true;

    $("#instruction-files").css({
      color: !useScanClicked ? "#34495e" : scanUploaded ? "#34495e" : "red",
    });

    $("label#upload-ct.form-label span").css({
      color: !deepCbctCourseClicked
        ? "#34495e"
        : ctUploaded
        ? "#34495e"
        : "red",
    });

    if (
      (!["first_name", "last_name"].filter((elm) => {
        const cond =
          this.props.instructions.hasOwnProperty(elm) &&
          this.props.instructions[elm] !== "";
        !cond ? emptyFields.push("patient-block-body") : null;
        $(`[name="${elm}"]`)
          .parent()
          .find("label")
          .css({ color: !cond ? "red" : "#34495e" });
        return !cond;
      }).length *
        // impressions is string and isNan doesn't work with it
        !["material"].filter((elm) => {
          const cond =
            this.props.formValues &&
            this.props.formValues.hasOwnProperty(elm) &&
            this.props.formValues[elm] !== null;
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        !["vertical_overlap_comment"].filter((elm) => {
          if (!vertical_overlap_comment) {
            return false;
          }
          const cond =
            this.props.formValues &&
            this.props.formValues.hasOwnProperty(elm) &&
            this.props.formValues[elm] !== "";
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        !["email"].filter((elm) => {
          let cond = true;
          if (
            deployedRussia() &&
            this.props.intl.locale == "ru" &&
            this.props.instructions.payer_id == Payer.PATIENT
          ) {
            cond =
              this.props.instructions.hasOwnProperty(elm) &&
              this.props.instructions[elm] &&
              validators.validateEmail(this.props.instructions[elm]) === true;
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          }
          return !cond;
        }).length *
        !["payer_id"].filter((elm) => {
          let cond = true;
          if (deployedRussia() && this.props.intl.locale == "ru") {
            cond =
              this.props.instructions.hasOwnProperty("payer_id") &&
              [1, 2, 3, "1", "2", "3"].includes(
                this.props.instructions["payer_id"]
              );
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          }
          return !cond;
        }).length *
        !["payment_option_id"].filter((elm) => {
          let cond =
            this.props.instructions.hasOwnProperty(elm) &&
            !isNaN(this.props.instructions[elm]) &&
            this.props.instructions[elm] !== null;
          if (cond === false && deployedRussia()) {
            cond =
              this.props.instructions.hasOwnProperty("sber_credit") &&
              this.props.instructions.sber_credit == true;
          }
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        !["clinic_id", "payment_method_id", "course_id"].filter((elm) => {
          const cond =
            this.props.instructions.hasOwnProperty(elm) &&
            this.props.instructions[elm] !== null &&
            !isNaN(this.props.instructions[elm]);
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        !["condition"].filter((elm) => {
          let cond = true;
          if (deployedUSA() === true && rxTypeId === RxType.STANDARD) {
            cond =
              this.props.instructions.hasOwnProperty(elm) &&
              this.props.instructions[elm] !== "" &&
              this.props.instructions[elm] !== null;
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
            return !cond;
          }
        }).length *
        !["comment"].filter((elm) => {
          let cond = true;
          if (deployedUSA() === true) {
            cond =
              this.props.formValues &&
              this.props.formValues.hasOwnProperty(elm) &&
              this.props.formValues[elm] !== "";
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
            return !cond;
          }
        }).length *
        !links.filter((elm) => {
          const cond = this.props.links && elm !== "";
          return !cond;
        }).length *
        !["arch"].filter((elm) => {
          const cond =
            this.props.formValues &&
            this.props.formValues.hasOwnProperty(elm) &&
            !isNaN(this.props.formValues[elm]) &&
            this.props.formValues[elm] !== null;
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        !["midline"].filter((elm) => {
          if (isRetainersCourse(course_id)) {
            return false;
          }
          let cond = true;
          if (deployedUSA() === true && rxTypeId === RxType.STANDARD) {
            cond =
              this.props.formValues &&
              this.props.formValues.hasOwnProperty(elm) &&
              this.props.formValues[elm] !== "" &&
              this.props.formValues[elm] !== null;
            !cond ? emptyFields.push(`validation-${elm}`) : null;
            $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
            return !cond;
          }
        }).length *
        !(['course_id'] as const).filter(() => {
          if (course_id && (isChildrenCourse(course_id) || isTeenCourse(course_id))) {
            const payer_id = this.props.instructions['payer_id'];
            if (payer_id == Payer.PATIENT) {
              return (
                ["payer_first_name", "payer_last_name", "payer_patronymic"].filter((property) => {
                  const isValid =
                    this.props.instructions.hasOwnProperty(property) &&
                    Boolean(this.props.instructions[property]);
                  if (!isValid) {
                    emptyFields.push("patient-block-body");
                  }
                  $(`[name="${property}"]`)
                    .parent()
                    .find("label")
                    .css({ color: !isValid ? "red" : "#34495e" });
                  return !isValid;
                }).length > 0
              );
            }
          }
          return false;
        }).length *
        ![
          "front_view",
          "full_face_with_smile",
          "full_face_without_smile",
          "profile",
          "lateral_view_left",
          "lateral_view_right",
          "occlusal_view_lower",
          "occlusal_view_upper",
        ].filter((elm) => {
          if (isRetainersCourse(course_id)) {
            return false;
          }
          const cond =
            (this.props.media[elm] && this.props.media[elm].md5) ||
            !!((((this.props || {}).patient || {}).media || {})
              .required_images || {})[elm];
          !cond ? emptyFields.push("photo-protocol-block") : null;
          $(`#${elm}`)
            .parent()
            .prev()
            .prev()
            .css({ color: !cond ? "red" : "#34495e" });
          return !cond;
        }).length *
        //if use scan is clicked and uploaded files contains .stl
        // Boolean(useScanClicked ? scanUploaded : true)
        //unless deep cpct is clicked previous scenario is checked, after user clicks on deep cbct ctUploaded is verified
        Boolean(
          (useScanClicked ? scanUploaded : true) &&
            (deepCbctCourseClicked ? ctUploaded : true)
        ) &&
        (!this.props.instructions.bdate ||
          (this.props.instructions.bdate &&
            this.props.instructions.bdate !== "Invalid date"))) ||
      this.props.patient.course.correction.slice().pop().index > 0 ||
      (isRetainersCourse(course_id) &&
        !this.props.patient.course.correction.slice().pop().order_options
          .full_edit) ||
      (this.props.patient.course.correction.slice().pop().index === 0 &&
        this.props.patient.course.correction.slice().pop().approved_plan_id)
    ) {
      this.setState({ showLoader: true, submit: true });
      if (this.props.patient.status == Status.UNFILLED_CASE) {
        this.props.instructions.prescription = convertToJSONFullRx(this.props.formValues);
        this.props.instructions.prescription.rx_type_id = rxTypeId;
        this.props.instructions.links = links;
        this.props.updateAndSubmitPatient(this.props.match.params.patient_id, {
          instructions: this.props.instructions,
          media: this.props.media,
          media_s3: this.props.media_s3,
        });
      } else if (
        this.props.patient.course.correction.length > 1 &&
        lastCorrection.order_options.can_edit_prescription === true &&
        !lastCorrection.order_options.full_edit === false
      ) {
        this.props.instructions.prescription = convertToJSONFullRx(this.props.formValues);
        this.props.instructions.prescription.rx_type_id = rxTypeId;
        this.props.instructions.links = links;
        this.props.updatePatient(this.props.match.params.patient_id, {
          instructions: this.props.instructions,
          media: this.props.media,
          media_s3: this.props.media_s3,
        });
      } else if (lastCorrection.order_options.can_edit_prescription === false) {
        this.props.instructions.links = links;
        this.props.updatePatient(this.props.match.params.patient_id, {
          instructions: this.props.instructions,
          media: this.props.media,
          media_s3: this.props.media_s3,
        });
      } else {
        this.props.instructions.prescription = convertToJSONFullRx(this.props.formValues);
        this.props.instructions.prescription.rx_type_id = rxTypeId;
        this.props.instructions.links = links;
        this.props.updatePatient(this.props.match.params.patient_id, {
          instructions: this.props.instructions,
          media: this.props.media,
          media_s3: this.props.media_s3,
        });
      }
    } else {
      const scrollToFields: string[] = [];
      requiredFields.forEach((f) =>
        emptyFields.forEach((a) => (a == f ? scrollToFields.push(f) : null))
      );
      ScrollToField(scrollToFields[0]);
      $("#submit-pacient-btn").removeClass("-error");
      setTimeout(() => {
        $("#submit-pacient-btn").addClass("-error");
      }, 0);
    }
  }

  submitSaveButton() {
    _paq.push(["trackEvent", "Update patient", "Save as a draft"]);
    if ($("#save-pacient-btn").attr("disabled")) return;

    /**For some old cases prescription can be nullable. NOTE: check prescription existence before trying to enter fields */
    const rxTypeId =
      this.props.patient &&
      this.props.patient.course.correction &&
      this.props.patient.course.correction[
        this.props.patient.course.correction.length - 1
      ].prescription
        ? this.props.patient.course.correction[
            this.props.patient.course.correction.length - 1
          ].prescription.rx_type_id
        : this.props.patient.rx_type_id;
    const emptyFields = [];
    const links = [];
    const linksArray =
      this.props.links && this.props.links.links ? this.props.links.links : [];
    linksArray.forEach((el) => links.push(...Object.values(el)));
    const vertical_overlap_comment =
      (this.props.formValues &&
        this.props.formValues.hasOwnProperty("vertical_overlap") &&
        this.props.formValues &&
        this.props.formValues.vertical_overlap === 1) ||
      (this.props.formValues && this.props.formValues.vertical_overlap === 2);

    if (
      !["first_name", "last_name"].filter((elm) => {
        const cond =
          this.props.instructions.hasOwnProperty(elm) &&
          this.props.instructions[elm] !== "";
        !cond ? emptyFields.push("patient-block-body") : null;
        $(`[name="${elm}"]`)
          .parent()
          .find("label")
          .css({ color: !cond ? "red" : "#34495e" });
        return !cond;
      }).length *
      !["vertical_overlap_comment"].filter((elm) => {
        if (!vertical_overlap_comment) {
          return false;
        }
        const cond =
          this.props.formValues.hasOwnProperty(elm) &&
          this.props.formValues[elm] !== "";
        !cond ? emptyFields.push(`validation-${elm}`) : null;
        $(`#validation-${elm}`).css({ color: !cond ? "red" : "#34495e" });
        return !cond;
      }).length *
      !links.filter((elm) => {
        const cond = this.props.links && elm !== "";
        return !cond;
      }).length
    ) {
      this.setState({ showLoader: true, submit: true });
      this.props.instructions.prescription = convertToJSONFullRx(
        this.props.formValues
      );
      this.props.instructions.prescription.rx_type_id = rxTypeId;
      this.props.instructions.links = links;
      this.props.updatePatient(this.props.match.params.patient_id, {
        instructions: this.props.instructions,
        media: this.props.media,
        media_s3: this.props.media_s3,
      });
    } else {
      this.setState({ submit: false });
      const scrollToFields = [];
      requiredFields.forEach((f) =>
        emptyFields.forEach((a) => (a == f ? scrollToFields.push(f) : null))
      );
      ScrollToField(scrollToFields[0]);
      $("#save-pacient-btn").removeClass("-error");
      setTimeout(() => {
        $("#save-pacient-btn").addClass("-error");
      }, 0);
    }
  }

  componentDidMount() {
    const { user } = this.props;

    window.scrollTo(0, 0);
    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }
    this.setState({ currentPath: window.location.pathname });

    // let patient_id = this.props.match.params.patient_id;
    this.props.getData(this.state.patient_id);
    this.props.initInstrucrions();
    this.props.eraseMedia();
    this.props.getCourseInstallment();
    setDocumentTitle(this.props.intl.formatMessage({ id: "BTN_EDIT" }) + " " + this.state.patient_id);
  }

  UNSAFE_componentWillUpdate(nextProps: PatientUpdateProps) {
    if (nextProps.patient && nextProps.patientSaving) {
      this.props.patientIsSaving(false);
      this.props.erasePatient("patient");
      this.props.history.push("/pages/patients");
    }
  }

  componentDidUpdate() {
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const { isDirty, submit } = this.state;
    const formIsDirty =
      !submit &&
      (isDirty ||
        dirtyCorrection ||
        dirtyLinks ||
        Object.keys(media).length >= 1 ||
        (media_s3.files && media_s3.files.length >= 1));
    if (formIsDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  componentWillUnmount() {
    this.props.erasePatient();
    window.onbeforeunload = undefined;
  }

  setDirty(key, data) {
    const patient_data = this.props.patient;
    if (
      data == "" ||
      (patient_data.hasOwnProperty(key) && data !== patient_data[key]) ||
      (patient_data.course.hasOwnProperty(key) &&
        data !== patient_data.course[key]) ||
      (patient_data.clinic.hasOwnProperty(key) &&
        data !== patient_data.clinic[key])
    ) {
      this.setState({ isDirty: true });
    } else {
      this.setState({ isDirty: false });
    }
  }

  fm(id: MessageDescriptor["id"]) {
    return this.props.intl.formatMessage({ id: id });
  }

  renderForm(
    course_id,
    canine_method_specify = false,
    molars_method_specify = false,
    upper_horizontal_overlap,
    lower_horizontal_overlap,
  ) {
    return deployedRussia() ? (
      <RecipeFormSmile course_id={course_id} />
    ) : (
      <RecipeFormPredict
        course_id={course_id}
        canine_method_specify={canine_method_specify}
        molars_method_specify={molars_method_specify}
        upper_horizontal_overlap={upper_horizontal_overlap}
        lower_horizontal_overlap={lower_horizontal_overlap}
      />
    );
  }

  renderRx(rxTypeId, course_id) {
    if (rxTypeId === RxType.STANDARD) {
      return this.renderForm(course_id);
    } else {
      return <RecipeFormShortRX course_id={course_id} />;
    }
  }

  render() {
    if (isPatient(this.props.patient)) {
      const lastCorrection = getLastCorrection(this.props.patient);
      const rxTypeId = lastCorrection.prescription ? lastCorrection.prescription.rx_type_id : this.props.patient.rx_type_id;
      const full_edit = lastCorrection.order_options.full_edit;
      const course_id = this.props.instructions.course_id ? this.props.instructions.course_id : this.props.patient.course.course_id;
      const patient_status = this.props.patient.status;
      const disable_on_3d_smile_pro = this.props.patient.status == Status.UNFILLED_CASE && is3DPROCourse(this.props.patient.course.course_id);
      const can_edit_prescription = lastCorrection.order_options.can_edit_prescription;
      const prescription = lastCorrection.prescription;
      const material = prescription ? prescription.material : null;
      const canine_method = prescription ? prescription.canine_method : null;
      const canine_ipr = prescription ? prescription.canine_ipr : null;
      const molars_method = prescription ? prescription.molars_method : null;
      const molars_ipr = prescription ? prescription.molars_ipr : null;
      const lower_horizontal_overlap = prescription ? prescription.lower_horizontal_overlap : null
      const upper_horizontal_overlap = prescription ? prescription.upper_horizontal_overlap : null
      const canine_method_specify = canine_ipr || canine_method;
      const molars_method_specify = molars_ipr || molars_method;
      const correction_num = this.props.patient.course.correction.length;
      const has_medit_files = this.props.patient.s3_media && this.props.patient.s3_media.find((f) => f.file_type == FILE_TYPE_MEDIT) !== undefined;

      const { isDirty, submit } = this.state;
      const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
      const formIsDirty =
        !submit &&
        (isDirty ||
          dirtyCorrection ||
          dirtyLinks ||
          Object.keys(media).length >= 1 ||
          (media_s3.files && media_s3.files.length >= 1));
      const { correction_media, required_images } = this.props.patient.media;

      return (
    <Layout>
      <div className="row">
        <div className="col-md-12">
        <Portlet as="main">
          <div className="portlet-title">
            <div className="col-md-8 col-sm-8 col-xs-8 caption">
              <i className="icon-book-open font-green" />
              <span className="caption-subject font-green bold uppercase">
                <FormattedMessage id="BTN_EDIT" />
              </span>
            </div>
            <div
              className="col-md-4 col-sm-4 col-xs-4"
              style={{ padding: "8px 0" }}
            >
              <label>
                <span
                  className="required mob-title"
                  aria-required="true"
                  style={{}}
                >
                  *{" "}
                </span>
                <i className="mob-title">
                  - <FormattedMessage id="requiredField" />
                </i>
                <span className="prescription_tooltip_show">
                  <i
                    style={{ fontSize: "12px", marginLeft: "6px" }}
                    className="icon-question"
                  />
                  <span
                    style={{
                      right: isMobile ? "-30px" : "",
                      position: "absolute",
                    }}
                    className="prescription_tooltip"
                  >
                    <FormattedMessage id="required.fields.tooltip" />
                  </span>
                </span>
              </label>
            </div>
          </div>
          <RouteLeavingGuard
            when={formIsDirty}
            shouldBlockNavigation={() => formIsDirty}
            saveChanges={this.props.patient.status == Status.UNFILLED_CASE ? this.submitSaveButton : this.submitButton}
            saveAsADraft={false}
          />
          <div className="portlet-body form">
            <div id="add-patient-form">
              <div className="form-body">
                <div className="alert alert-danger display-hide">
                  <button className="close" data-close="alert" />
                  <span id="error-msg" />
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <PatientUpdateDoctorInfo setDirty={this.setDirty} />
                  </div>
                  {has_medit_files === true ? (
                    <div className="col-xs-10 col-sm-5">
                      <div
                        className="alert alert-success alert-dismissible show"
                        role="alert"
                        id="upload-medit-alert-warning"
                      >
                        <FormattedMessage id="th.medit.file.upload_success" />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {full_edit === true ? (
                  <div>
                    <PatientUpdatePayment setDirty={this.setDirty} />
                    <PatientUpdatePatientInfo setDirty={this.setDirty} />
                    <PatientUpdateInstructionsCourse
                      setDirty={this.setDirty}
                      rxTypeId={rxTypeId}
                    />
                    {rxTypeId === RxType.STANDARD ? (
                      this.renderForm(
                        course_id,
                        canine_method_specify,
                        molars_method_specify,
                        upper_horizontal_overlap,
                        lower_horizontal_overlap
                      )
                    ) : (
                      <RecipeFormShortRX course_id={course_id} />
                    )}
                  </div>
                ) : deployedUSA() ? (
                  <PatientUpdatePayment setDirty={this.setDirty} />
                ) : null}
                {can_edit_prescription && !full_edit ? (
                  <ImpressionScanOptions material={material} />
                ) : null}
                <PatientUpdateInstructionsNewMedia
                  showUploadedFiles={true}
                  setDirty={this.setDirty}
                  has_medit_files={has_medit_files}
                />
                {!isRetainersCourse(course_id) && (
                  <PatientUpdateInstructionsUploadCt showUploadedFiles={true} />
                )}
                {!isRetainersCourse(course_id) && <LinksCT />}
                {this.props.patient.status == Status.UNFILLED_CASE &&
                !isRetainersCourse(course_id) ? (
                  <PatientUpdateInstructionsNewImages
                    setDirty={this.setDirty}
                  />
                ) : null}
                {deployedUSA() &&
                correction_num > 1 &&
                (correction_media[`${correction_num - 1}`] === undefined ||
                  Object.keys(required_images).length !==
                    Object.keys(correction_media[`${correction_num - 1}`])
                      .length) ? (
                  <PlanCorrectionPhoto correctionNumber={correction_num - 1} />
                ) : null}
                {can_edit_prescription && !full_edit
                  ? this.renderRx(rxTypeId, course_id)
                  : null}

                <input
                  type="hidden"
                  id="doctor-id"
                  name="doctor_id"
                  defaultValue={264}
                />
              </div>
              <div className="form-actions">
                <button
                  type="button"
                  className="btn green"
                  id="submit-pacient-btn"
                  disabled={disable_on_3d_smile_pro}
                  onClick={() => this.submitButton()}
                >
                  <FormattedMessage
                    id={patient_status == Status.UNFILLED_CASE ? "BTN_SUBMIT_NEW" : "BTN_SAVE"}
                  />
                </button>
                {patient_status == Status.UNFILLED_CASE ? (
                  <button
                    type="button"
                    style={{ margin: 2, marginLeft: 8 }}
                    className="btn default"
                    id="save-pacient-btn"
                    onClick={() => this.submitSaveButton()}
                  >
                    <FormattedMessage id="BTN_SAVE_DRAFT" />
                  </button>
                ) : null}
              </div>
              {this.state.showLoader && <Loader />}
            </div>
          </div>
        </Portlet>
        </div>
        </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        </Layout>
      );

    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdate));
