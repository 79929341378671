import { FETCH_EVENT, FETCH_EVENT_ERROR, FETCH_EVENT_SUCCESS } from "../actions/events";
import type { QueryState } from "../types/redux";

export type TEvent = {
  id: number;
  title: string;
  city: string | null;
  start_date: string;
  end_date: string | null;
  duration: number | null;
  enrolled: boolean;
  payment: PaymentStatus | null;
  available_seats: number | null;
  meeting_type: EventMeetingType;
  free: boolean;
  price: number | null;
  image: { small: string | null };
  recording_link: string | null;
  feedback: boolean;
  zoom_join_url: string | null;
};

export enum EventMeetingType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  RECORDING = "RECORDING",
}

export enum PaymentStatus {
  CREATED = "CREATED",
  PROCESSING = "PROCESSING",
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
  SUCCESS = "SUCCESS",
}

export type TEventDetail = {
  id: TEvent["id"];
  title: TEvent["title"];
  city: TEvent["city"];
  start_date: TEvent["start_date"];
  discount: number | null;
  max_discount: number;
  smiles: number;
  rate: (TEventRate & { minimal: number }) | null;
  /** `true` if the current doctor registered for the event. */
  registered: boolean;
  status: TEventStatus | null;
  description: string | null;
  available_seats: TEvent["available_seats"];
  meeting_type: TEvent["meeting_type"];
  meeting_link: string | null;
  finished: boolean;
  /** `true` if the current doctor left a feedbabck for the event. */
  feedback: TEvent["feedback"];
  requires_email: boolean;
  package_rate: TEventRate | null;
  cycle_info: ReadonlyArray<{ title: string; start_date: string }> | null;
  zoom_join_url: string | null;
};

type TEventRate = {
  tag: "EVENT_STANDARD" | "EVENT_RESIDENT" | "EVENT_ADVANCE" | "EVENT_PACKAGE";
  price: number;
};

type TEventStatus = {
  smiles: number;
  blocked: boolean;
  payment: boolean;
  amount: number | null;
  status: PaymentStatus | null;
};

type EventState = QueryState<TEventDetail>;
type EventAction =
  | { type: typeof FETCH_EVENT }
  | { type: typeof FETCH_EVENT_SUCCESS; json: TEventDetail }
  | { type: typeof FETCH_EVENT_ERROR; error: Error };

const eventInitialState: EventState = {
  status: "idle",
  data: null,
  error: null,
};

export function event(state: EventState = eventInitialState, action: EventAction): EventState {
  switch (action.type) {
    case FETCH_EVENT:
      return { status: "loading", data: null, error: null };

    case FETCH_EVENT_SUCCESS:
      return { status: "success", data: action.json, error: null };

    case FETCH_EVENT_ERROR:
      return { status: "error", data: null, error: action.error };

    default:
      return state;
  }
}
