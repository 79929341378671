import type { MessageDescriptor } from "react-intl";
import type { Notification as ReactNotificationType } from "react-notification-system";
import { v4 as uuidv4 } from "uuid";

import type { getBannersAction } from "../actions/banners";
import {
  CREATE_SHIPMENT,
  CREATE_SHIPMENT_ERROR,
  CREATE_SHIPMENT_SUCCESS,
  type DeliveryDestination,
  GET_DELIVERIES,
  GET_DELIVERIES_ERROR,
  GET_DELIVERIES_SUCCESS,
  PICKUP,
  PICKUP_ERROR,
  PICKUP_SUCCESS,
  type SERVICE_ME,
  type SERVICE_UPS,
} from "../actions/deliveries";
import type { clearExtraServices } from "../actions/extra_services";
import type { courseSelectedAction, selectCourseAction } from "../actions/get_course_installment";
import type { getTokenAction } from "../actions/get_token";
import type { getTour } from "../actions/get_tour";
import { GET_PATIENT_PAYMENT_SUCCESS } from "../actions/invoice";
import {
  AUTHENTICATE_PATIENT,
  AUTHENTICATE_PATIENT_SUCCESS,
  SIGN_PATIENT_OFFER,
  SIGN_PATIENT_OFFER_SUCCESS,
} from "../actions/offer";
import type {
  addInstructions,
  createInstructionsSuccess,
  removeInstructions,
} from "../actions/patient_new_instructions";
import type { patientIsSavingSuccess } from "../actions/post_patient";
import type { getSmilesAction } from "../actions/smiles";
import type {
  addUserActionNotification,
  eraseUserActionNotification,
} from "../actions/user_notification";
import type { Gender, Payer, PaymentMethod, PaymentMethodId, PaymentOption, RxType, SOArch } from "../common/constants";
import type { Case } from "../common/courses";
import type { MATERIAL_IMPRESSIONS, MATERIAL_SCAN } from "../common/prescription";
import type { TUser } from "./login";
import type { TOffer } from "./offer";

type InvoiceState = { link: string } | { invoice: { formUrl: string } };

type InvoiceAction = { type: "GET_INVOICE_DATA"; json: InvoiceState };

export function invoice(
  state: Partial<InvoiceState> = {},
  action: InvoiceAction,
): Partial<InvoiceState> {
  switch (action.type) {
    case "GET_INVOICE_DATA":
      return { ...state, ...action.json };

    case "ERASE_INVOICE_DATA":
      return {};

    default:
      return state;
  }
}

export type TUserActionNotification = ReactNotificationType & { message: MessageDescriptor["id"] };
type UserActionNotificationState = TUserActionNotification | null;
type UserActionNotificationAction = ReturnType<
  typeof addUserActionNotification | typeof eraseUserActionNotification
>;

export function userActionNotification(
  state: UserActionNotificationState = null,
  action: UserActionNotificationAction,
): UserActionNotificationState {
  switch (action.type) {
    case "ADD_USER_ACTION_NOTIFICATION":
      return { ...state, ...action.json };

    case "ERASE_USER_ACTION_NOTIFICATION":
      return null;

    default:
      return state;
  }
}

type ClinicAction =
  | { type: "GET_CLINIC_SUCCESS"; json: TClinic }
  | { type: "POST_CLINIC_SUCCESS"; json: TClinic };

export function clinic(state: Partial<TClinic> = {}, action: ClinicAction): Partial<TClinic> {
  switch (action.type) {
    case "GET_CLINIC_SUCCESS":
      return action.json;

    case "POST_CLINIC_SUCCESS":
      return action.json;

    default:
      return state;
  }
}

export type TClinic = {
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  clinic_id: number;
  contact: string;
  email: string;
  logistic_contact: string;
  logistic_phone: string;
  phone: string;
  schedule_json: Record<number, { from: string; to: string }>;
  title: string;
  zip_code: string;
};

type ClinicsAction =
  | { type: "GET_CLINICS_SUCCESS"; json: TClinic[] }
  | { type: "UNLINK_CLINICS_SUCCESS"; clinic_id: string };

export function clinics(state: TClinic[] = [], action: ClinicsAction): TClinic[] {
  switch (action.type) {
    case "GET_CLINICS_SUCCESS":
      return action.json;

    case "UNLINK_CLINICS_SUCCESS":
      return state.filter((x) => x.clinic_id !== parseInt(action.clinic_id));

    default:
      return state;
  }
}

export function patientSaving(
  state = false,
  action: ReturnType<typeof patientIsSavingSuccess>,
): boolean {
  switch (action.type) {
    case "PATIENT_SAVE_SUCCESSFULL":
      return action.patientIsSaving;

    default:
      return state;
  }
}

export function fileUploaded(state = false, action) {
  switch (action.type) {
    case "FILE_UPLOAD_SUCCESSFULL":
      return action.fileUploaded;

    default:
      return state;
  }
}

export type TCourse = {
  course_id: Case;
};

export type TCorrection = {
  id: number;
  order_options: {
    can_order_delivery: boolean;
    can_order_correction: boolean;
    can_order_retainers: boolean;
    can_end_treatment: boolean;
    can_order_additional_aligners: boolean;
    can_edit_prescription: boolean;
    full_edit: boolean;
    should_fill_delivery_info: boolean;
    ups_deliveries_privilege: 0 | 1;
  };
  /** For some old cases prescription can be nullable */
  prescription: TPrescription | null;
  steps_count_total: number | "None";
  steps_count_completed: number | "None";
  timestamp: string;
  plan_date: string | null;
  start_date: string | null;
  approval_timestamp: string | null;
  // Can be empty string for one render when you go to patient edit page -> back to patient page in some cases.
  // Logs an error in intl js.
  payment_status: "PS_3D_PLAN" | "PS_HALF_PRICE" | "PS_FULL_PRICE" | "NA" | "";
  links: { link: string; timestamp: string }[];
  with_ct: boolean | null;
};

type TPrescription = {
  rx_type_id: RxType;
  material: typeof MATERIAL_SCAN | typeof MATERIAL_IMPRESSIONS;
  correct_proclination_lower: boolean;
  correct_proclination_upper: boolean;
  correct_retroclination_lower: boolean;
  correct_retroclination_upper: boolean;
  comment: string;
  elastics_options: Record<number, number>;
  close_all_gaps: null;
  close_all_gaps_spaces: Record<number, number>;
  close_all_gaps_value: string;
  vertical_overlap: null;
  vertical_overlap_comment: string;
  upper_horizontal_overlap: null;
  lower_horizontal_overlap: null;
  overjet: null;
  midline: null;
  elastics_recoil_left: null;
  elastics_recoil_right: null;
  teething: unknown[];
  canine_method: null;
  canine_ipr: boolean;
  molars_method: null;
  molars_ipr: boolean;
};

export type TPatient = {
  bdate: string | null;
  patient_id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  sex: Gender | null;
  email: string | null;
  course: {
    course_id: TCourse["course_id"] | "None";
    correction: TCorrection[];
    payment_method: PaymentMethod;
    payment_option: "PO_ADVANCE" | "PO_SPLIT_2" | "PO_SPLIT_3";
    payments: {
      next: number;
      remain: number;
      total: number;
      cbct?: boolean;
      extra?: Pick<TService, "id" | "price" | "tag">[];
    };
    course:
      | "C_MATCHING"
      | "C_FULL"
      | "C_SHORT"
      | "C_ONE_JAW"
      | "C_SUPER_SHORT"
      | "C_UNLIMITED"
      | "C_3D_PLAN"
      | "C_CHILDREN"
      | "C_RETAINERS"
      | "C_3DSMILE_PRO"
      | "C_TEEN"
      | "C_CHILDREN_SHORT"
      | "None";
    course_option_tag: "CHILDREN_V3" | "None";
  };
  status:
    | "S_UNFILLED_CASE"
    | "S_INSTRUCTIONS_PROCESSING"
    | "S_RETAKE_IMPRESSIONS"
    | "S_WAITING_FOR_A_PAYMENT"
    | "S_3D_SCANNING"
    | "S_3D_PLAN_MODELING"
    | "S_3D_PLAN_APPROVAL"
    | "S_3D_PLAN_MODELS_BUILDING"
    | "S_PRODUCTION_PRINTING"
    | "S_PRODUCTION_MOLDING"
    | "S_PRODUCTION_MARKING"
    | "S_PRODUCTION_ALLOCATING"
    | "S_PRODUCTION_FABRICATING"
    | "S_PRODUCTION_QUALITY_CONTROL"
    | "S_FINAL_STAGE_READY"
    | "S_TREATMENT_FINISHED"
    | "S_CANCEL_TREATMENT"
    | "S_RETAINERS_ORDERED"
    | "S_RETAINERS_PRINTING"
    | "S_RETAINERS_FABRICATING"
    | "S_RETAINERS_READY"
    | "S_RETAINERS_3D_SCANNING";
  total_payments: {
    paid: number;
    total: number;
  };
  payer_id: Payer;
  deep_cbct: boolean | null;
  course_version: number;
  rx_type_id: RxType;
  can_edit_payment_option: boolean;
  sber_credit: boolean;
  payer_first_name: string | null;
  payer_last_name: string | null;
  payer_patronymic: string | null;
  bonus_set_free: boolean;
  media: {
    "3d-plan": { response: "True" } | "NETWORK_ERROR";
    correction_media: Record<string, TCorrectionMedia>;
    optional_media: TOptionalMedia[];
    required_images: TCorrectionMedia;
  };
  deliveries: TDelivery[];
  delivery_estimate: null;
  delivery_additional_estimate: null;
  delivery_retainers_estimate: null;
  warranty_end_date: string | null;
  bonus: boolean;
  timestamp: string;
  clinic: Partial<TClinic>;
  can_set_teen_course: boolean;
  s3_media: unknown[];
  logistics_comment: string | null;
};

type TOptionalMedia = {
  creation_date: number;
  linkto: string;
  media_size: number;
  thumbnail: string;
};

type TCorrectionMedia = {
  front_view: TCorrectionMediaView;
  full_face_with_smile: TCorrectionMediaView;
  full_face_without_smile: TCorrectionMediaView;
  lateral_view_left: TCorrectionMediaView;
  lateral_view_right: TCorrectionMediaView;
  occlusal_view_lower: TCorrectionMediaView;
  occlusal_view_upper: TCorrectionMediaView;
  profile: TCorrectionMediaView;
};

export type TCorrectionMediaView = {
  linkto: string;
  thumbnail: string;
};

type PatientAction =
  | { type: "GET_PATIENT_SUCCESS"; json: TPatient }
  | {
      type: "ADD_PATIENT_SUCCESSFULL";
      json: Pick<TPatient, "first_name" | "last_name" | "patient_id">;
    }
  | {
      type: "UPDATE_PATIENT_SUCCESSFULL";
      json: Pick<TPatient, "first_name" | "last_name" | "patient_id">;
    }
  | { type: "POST_STATUS_END"; json: TPatient }
  | { type: "POST_STATUS_RETAINERS"; json: TPatient }
  | { type: "POST_PLAN_APPROVED"; json: TPatient }
  | { type: "POST_PLAN_SEND_TO_REWORK"; json: TPatient }
  | { type: "POST_BONUS_PATIENT"; json: TPatient }
  | { type: "ERASE_PATIENT"; json: Record<string, unknown> };

export function patient(state: Partial<TPatient> = {}, action: PatientAction): Partial<TPatient> {
  switch (action.type) {
    case "GET_PATIENT_SUCCESS":
      return action.json;
    case "ADD_PATIENT_SUCCESSFULL":
      return action.json;
    case "UPDATE_PATIENT_SUCCESSFULL":
      return action.json;
    case "POST_STATUS_END":
      return action.json;
    case "POST_STATUS_RETAINERS":
      return action.json;
    case "POST_PLAN_APPROVED":
      return action.json;
    case "POST_PLAN_SEND_TO_REWORK":
      return action.json;
    case "POST_BONUS_PATIENT":
      return action.json;
    case "ERASE_PATIENT":
      return {};
    default:
      return state;
  }
}

export function token(
  state: string | null = null,
  action: ReturnType<typeof getTokenAction>,
): string | null {
  switch (action.type) {
    case "GET_TOKEN_SUCCESS":
      return action.fields.token;

    default:
      return state;
  }
}

type PatientsState = {
  patients: TPatientList[];
  payment_alert: boolean;
  total: number;
  all: number;
};

export type TPatientList = {
  course: Pick<TPatient["course"], "course">;
  course_version: TPatient["course_version"];
  first_name: TPatient["first_name"];
  last_name: TPatient["last_name"];
  latest_correction: {
    approved_plan_id: "None";
    price_value_paid: number;
    price_value_total: number;
    status: TPatient["status"];
    steps_count_completed: TCorrection["steps_count_completed"];
    steps_count_total: TCorrection["steps_count_total"];
    timestamp: TCorrection["timestamp"];
  };
  patient_id: TPatient["patient_id"];
  precise_initial_adjust_timestamp: string | null;
  precise_latest_adjust_timestamp: string | null;
  total_payments: TPatient["total_payments"];
};

const patientsInitialState: PatientsState = {
  patients: [],
  payment_alert: false,
  total: 0,
  all: 0,
};

type PatientsAction =
  | { type: "GET_PATIENTS_SUCCESS"; json: PatientsState }
  | { type: "LOAD_SPINNER" };

export function patients(
  state: PatientsState = patientsInitialState,
  action: PatientsAction,
): PatientsState {
  switch (action.type) {
    case "GET_PATIENTS_SUCCESS":
      return { ...state, ...action.json };

    case "LOAD_SPINNER":
      return { ...state, patients: [] };

    default:
      return state;
  }
}

export function meditPatients(state = [], action) {
  switch (action.type) {
    case "GET_MEDIT_PATIENTS_SUCCESS":
      return action.json;

    default:
      return state;
  }
}

type DoctorTasksState = {
  patients: TPatientList[];
  payment_alert: boolean;
  hasBeenLoaded: boolean;
};

type DoctorTasksAction = {
  type: "GET_DOCTORS_TASKS_SUCCESS";
  json: Pick<DoctorTasksState, "patients" | "payment_alert">;
};

export function doctorTasks(
  state: DoctorTasksState = { patients: [], payment_alert: false, hasBeenLoaded: false },
  action: DoctorTasksAction,
): DoctorTasksState {
  switch (action.type) {
    case "GET_DOCTORS_TASKS_SUCCESS":
      return { ...action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

export type TDoctorPayment = {
  course: TPatient["course"]["course"];
  course_option_tag: "CHILDREN_V3" | "None";
  first_name: TPatient["first_name"];
  last_name: TPatient["last_name"];
  patient_id: TPatient["patient_id"];
  status: TPatient["status"];
  payments: {
    next: number;
    remain: number;
    tota: number;
    cbct?: boolean;
    extra?: TDoctorPaymentExtra[];
  };
  total_payments: {
    paid: number;
    total: number;
  };
};

type TDoctorPaymentExtra = {
  id: number;
  price: number;
  tag:
    | "ADDITIONAL_ALIGNERS"
    | "CBCT_ANALYSIS"
    | "DELIVERY"
    | "RETAINERS"
    | "RETAINERS_LOWER_ARCH"
    | "RETAINERS_UPPER_ARCH";
};

type DoctorPaysState = {
  patients: TDoctorPayment[];
  hasBeenLoaded: boolean;
};

type DoctorPaysAction = {
  type: "GET_DOCTORS_PAYS_SUCCESS";
  json: TDoctorPayment[];
};

export function doctorPays(
  state: DoctorPaysState = { patients: [], hasBeenLoaded: false },
  action: DoctorPaysAction,
): DoctorPaysState {
  switch (action.type) {
    case "GET_DOCTORS_PAYS_SUCCESS":
      return { patients: action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

export type TInstructions = {
  rx_type_id: RxType;
  doctor_id: number;
  payer_id: Payer;
  payment_method_id: PaymentMethodId | null;
  clinic_id: number;
  course_id?: number;
  payment_option_id?: PaymentOption | null;
  deep_cbct?: boolean | null;
  sber_credit?: boolean;
  condition?: string;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  bdate?: string | null;
  payer_last_name?: string | null;
  payer_first_name?: string | null;
  payer_patronymic?: string | null;
  sex?: number | null;
};

type InstructionsAction =
  | ReturnType<typeof addInstructions>
  | ReturnType<typeof removeInstructions>
  | ReturnType<typeof createInstructionsSuccess>
  | { type: "ERASE_INSTRUCTIONS" };

export function instructions(
  state: Partial<TInstructions> = {},
  action: InstructionsAction,
): Partial<TInstructions> {
  switch (action.type) {
    case "ADD_PATIENT_INSTRUCTION":
      return { ...state, ...action.json };

    case "REMOVE_PATIENT_INSTRUCTION":
      Object.keys(action.json).forEach((key) => {
        delete state[key];
      });
      return state;

    case "CREATE_PATIENT_INSTRUCTION":
      return {};

    case "ERASE_INSTRUCTIONS":
      return {};

    default:
      return state;
  }
}

type MediaState = Partial<Record<keyof TCorrectionMedia, TMediaValue>>;

type TMediaValue = {
  img: Record<string, unknown>;
  semantics: keyof TCorrectionMedia;
  id: keyof TCorrectionMedia;
  patient_id: number;
  md5: string;
  status: "ok";
  user_filename: string;
};

type MediaAction =
  | { type: "PATIENT_IMAGE_PREVIEW"; image: Omit<TMediaValue, "status" | "user_filename"> }
  | { type: "PATIENT_IMAGE_UPLOAD_SUCCESS"; json: Omit<TMediaValue, "img"> }
  | { type: "ADD_PATIENT_SUCCESSFULL" }
  | { type: "ERASE_MEDIA" };

export function media(state: MediaState = {}, action: MediaAction): MediaState {
  switch (action.type) {
    case "PATIENT_IMAGE_PREVIEW":
      return { ...state, [action.image.id]: action.image };

    case "PATIENT_IMAGE_UPLOAD_SUCCESS": {
      const ci = { ...state[action.json.id], ...action.json };
      return { ...state, [action.json.id]: ci };
    }

    // case "PATIENT_IMAGE_UPLOAD_ERROR":
    //   return Object.assign({}, state, { [action.json.id]: null, message: action.json.message })

    case "ADD_PATIENT_SUCCESSFULL":
      return {};

    case "ERASE_MEDIA":
      return {};

    default:
      return state;
  }
}

type MediaS3State = Partial<{
  uuid: string;
  files: string[];
}>;

type MediaS3Action =
  | { type: "media_s3/clear" }
  | { type: "CBCT_UPLOAD_SUCCESS"; payload: string[] };

export function media_s3(state: MediaS3State = {}, action: MediaS3Action): MediaS3State {
  switch (action.type) {
    case "media_s3/clear":
      return { uuid: uuidv4(), files: [] };

    case "CBCT_UPLOAD_SUCCESS":
      return { ...state, files: action.payload };

    default:
      return state;
  }
}

type DoctorAction = { type: "EDIT_DOCTOR_SUCCESS"; fields: TUser } | { type: "ERASE_DOCTOR" };

export function doctor(state: Partial<TUser> = {}, action: DoctorAction): Partial<TUser> {
  switch (action.type) {
    case "EDIT_DOCTOR_SUCCESS":
      return action.fields;

    case "ERASE_DOCTOR":
      return {};

    default:
      return state;
  }
}

export type TDelivery = {
  called_by_phone: boolean;
  correction_id: TCorrection["id"];
  correction_number: number;
  delivery_id: number;
  destination: DeliveryDestination;
  filename: string;
  pickup_date: string | null;
  prn: null;
  timestamp: string;
  track_number: string | null;
  type: typeof SERVICE_UPS | typeof SERVICE_ME;
};

type DeliveryState = {
  deliveries: TDelivery[];
  loading: boolean;
};

type DeliveryAction =
  | { type: typeof GET_DELIVERIES }
  | { type: typeof GET_DELIVERIES_ERROR }
  | { type: typeof GET_DELIVERIES_SUCCESS; fields: TDelivery[] }
  | { type: typeof PICKUP }
  | { type: typeof PICKUP_SUCCESS }
  | { type: typeof CREATE_SHIPMENT }
  | { type: typeof CREATE_SHIPMENT_SUCCESS; fields: TDelivery };

export function delivery(
  state: DeliveryState = { deliveries: [], loading: false },
  action: DeliveryAction,
): DeliveryState {
  switch (action.type) {
    case GET_DELIVERIES:
      return { ...state, loading: true };
    case GET_DELIVERIES_ERROR:
      return { ...state, deliveries: [], loading: false };
    case GET_DELIVERIES_SUCCESS:
      return { ...state, deliveries: action.fields, loading: false };

    case PICKUP:
      return { ...state, loading: true };
    case PICKUP_ERROR:
      return { ...state, loading: false };
    case PICKUP_SUCCESS:
      return { ...state, loading: false };

    case CREATE_SHIPMENT:
      return { ...state, loading: true };
    case CREATE_SHIPMENT_SUCCESS:
      return { deliveries: [action.fields, ...state.deliveries], loading: false };
    case CREATE_SHIPMENT_ERROR:
      return { deliveries: [], loading: false };

    /*
        case UPDATE_DELIVERY_INFO:
          return { deliveries: action.fields, loading: false };
        */

    default:
      return state;
  }
}

export type TPatientData = {
  course: {
    course: TPatient["course"]["course"];
    course_option_tag: TPatient["course"]["course_option_tag"];
    course_version: TPatient["course_version"];
    paid: number;
    payment_option: TPatient["course"]["payment_option"];
    payments: TPatient["course"]["payments"];
    services: unknown[];
    total: number;
    total_payments: TPatient["total_payments"];
  };
  offer: TOffer | null;
  patient_id: TPatient["patient_id"];
  signed: boolean;
};

type PatientDataAction =
  | { type: typeof AUTHENTICATE_PATIENT_SUCCESS }
  | { type: typeof SIGN_PATIENT_OFFER_SUCCESS; fields: TPatientData }
  | { type: typeof GET_PATIENT_PAYMENT_SUCCESS; fields: Omit<TPatientData, "course"> }
  | { type: typeof AUTHENTICATE_PATIENT };

export function patientData(
  state: Partial<TPatientData> = {},
  action: PatientDataAction,
): Partial<TPatientData> {
  switch (action.type) {
    case AUTHENTICATE_PATIENT_SUCCESS:
    case SIGN_PATIENT_OFFER_SUCCESS:
    case GET_PATIENT_PAYMENT_SUCCESS:
      if (action.fields) {
        return { ...action.fields };
      }
    case AUTHENTICATE_PATIENT:
    case SIGN_PATIENT_OFFER:
      return {};
    default:
      return state;
  }
}

type TCourseInstallment = Record<Case, PaymentOption[]>;

type CourseInstallmentAction = { type: "GET_COURSE_INSTALLMENT_SUCCESS"; json: TCourseInstallment };

export function courseInstallment(
  state: Partial<TCourseInstallment> = {},
  action: CourseInstallmentAction,
): Partial<TCourseInstallment> {
  switch (action.type) {
    case "GET_COURSE_INSTALLMENT_SUCCESS":
      return action.json;

    default:
      return state;
  }
}

export function selectCourseReducer(
  state: PaymentOption[] = [],
  action: ReturnType<typeof selectCourseAction>,
): PaymentOption[] {
  switch (action.type) {
    case "SELECT_COURSE":
      return action.data;

    default:
      return state;
  }
}

export function courseSelectedReducer(
  state = false,
  action: ReturnType<typeof courseSelectedAction>,
): boolean {
  switch (action.type) {
    case "COURSE_SELECTED_TRIGGER":
      return action.data;

    default:
      return state;
  }
}

export type TService = {
  id: number;
  paid: boolean;
  price: number;
  service_id: number;
  tag:
    | "RETAINERS"
    | "CBCT_ANALYSIS"
    | "DELIVERY"
    | "RETAINERS_UPPER_ARCH"
    | "RETAINERS_LOWER_ARCH"
    | "ADDITIONAL_ALIGNERS"
    | "CBCT_ANALYSIS_3D_PLAN";
  timestamp: string;
  option: {
    amount: number | null;
    elastics_left: 2 | 3 | null;
    elastics_right: 2 | 3 | null;
    material: null;
    stage: number | null;
    status?: "SAA_READY" | "SAA_PROCESSING";
    treat_arch_id: SOArch | null;
  };
};

type ServicesState = Record<string, unknown> | TService[];

type ServicesAction =
  | { type: "GET_EXTRA_SERVICES"; json: TService[] }
  | ReturnType<typeof clearExtraServices>;

export function services(state: ServicesState = {}, action: ServicesAction): ServicesState {
  switch (action.type) {
    case "GET_EXTRA_SERVICES":
      return action.json;

    case "CLEAR_EXTRA_SERVICES":
      return {};

    default:
      return state;
  }
}

type SpinnerAction = { type: "LOAD_SPINNER" } | { type: "CLEAR_SPINNER" };

export function spinner(state: boolean | null = null, action: SpinnerAction): boolean | null {
  switch (action.type) {
    case "LOAD_SPINNER":
      return true;

    case "CLEAR_SPINNER":
      return false;

    default:
      return state;
  }
}

export function fileSpinner(state = null, action) {
  switch (action.type) {
    case "LOAD_FILE_SPINNER":
      return true;

    case "CLEAR_FILE_SPINNER":
      return false;

    default:
      return state;
  }
}

export function fileUploadError(state = null, action) {
  switch (action.type) {
    case "UPLOAD_ERROR":
      return true;

    case "NO_ERROR":
      return false;

    default:
      return state;
  }
}

const CASES_STATE = {
  cases: [],
  count: null,
};

export function threeShapeCases(state = CASES_STATE, action) {
  switch (action.type) {
    case "GET_3SHAPE_CASES":
      return { ...state, ...action.json };

    default:
      return state;
  }
}

export function meditCases(state = [], action) {
  switch (action.type) {
    case "GET_MEDIT_CASES":
      return { ...action.json };

    default:
      return state;
  }
}

type BonusesState = {
  available: number;
  current: number;
  days: null;
  end: string;
  months: number;
  start: string;
  target: number;
  total: number;
  used: number;
};

type BonusesAction = { type: "GET_BONUSES"; json: BonusesState };

export function bonuses(
  state: Partial<BonusesState> = {},
  action: BonusesAction,
): Partial<BonusesState> {
  switch (action.type) {
    case "GET_BONUSES":
      return action.json || {};

    default:
      return state;
  }
}

export type TSmiles = {
  start: string;
  end: string;
  current: number;
  total: number;
};

export function smiles(
  state: Partial<TSmiles> = {},
  action: ReturnType<typeof getSmilesAction>,
): Partial<TSmiles> {
  switch (action.type) {
    case "GET_SMILES":
      return action.json;

    default:
      return state;
  }
}

export type TBanner = {
  id: number;
  type: "link" | "private_file" | "public_file" | "empty";
  position: number;
  align: "left" | "center" | "center_and_left";
  icon_url: string;
  banner_url: string | null;
  link: string | null;
  description: string | null;
};

export function banners(
  state: TBanner[] = [],
  action: ReturnType<typeof getBannersAction>,
): TBanner[] {
  switch (action.type) {
    case "GET_BANNERS":
      return action.json;

    default:
      return state;
  }
}

export type TShipment = {
  patient: Pick<TPatient, "patient_id" | "first_name" | "last_name">;
  tracking_number: string | null;
  pickup_date: string;
  type: "Major Express" | "UPS" | null;
};

type ShipmentsState = {
  data: TShipment[];
  hasBeenLoaded: boolean;
};

type ShipmentsAction = {
  type: "SHIPMENTS_SUCCESS";
  json: TShipment[];
};

export function shipments(
  state: ShipmentsState = { data: [], hasBeenLoaded: false },
  action: ShipmentsAction,
): ShipmentsState {
  switch (action.type) {
    case "SHIPMENTS_SUCCESS":
      return { data: action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

export function tour(state = false, action: ReturnType<typeof getTour>): boolean {
  switch (action.type) {
    case "GET_TOUR":
      return action.data;

    default:
      return state;
  }
}
