import React, { Component } from "react";
import { openPopupWidget } from "react-calendly";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";

import remoteLog from "../../common/logging";
import { deployedUSA } from "../../common/utils";
import { API_MEETINGS } from "../../config";
import { setDocumentTitle } from "../../hooks/use-document-title";
import Loader from "../common/loadingInProgress";
import { formatPersonName } from "../common/PersonName";
import { Layout } from "../ui/layout";
import { Portlet, PortletTitle } from "../ui/portlet";

const buttonStyles = {
  background: "none",
  border: "none",
  padding: "0!important",
  fontFamily: "arial, sans-serif",
  color: "#069",
  textDecoration: "underline",
  cursor: "pointer",
  fontSize: "larger",
};

type MeetingsStateProps = { intl: IntlShape };

type MeetingsPageState = {
  loading: boolean;
  links: Record<string, TMeetingLink> | null;
};

type TMeetingLink = {
  first_name: string;
  last_name: string;
  middle_name: string | null;
  link: string;
};

class MeetingsPage extends Component<MeetingsStateProps, MeetingsPageState> {
  constructor(props: MeetingsStateProps) {
    super(props);
    this.state = {
      loading: true,
      links: null,
    };
    this.renderExperts = this.renderExperts.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "meetings_page");
  }

  componentDidMount() {
    fetch(API_MEETINGS, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch meetings");
        }
        return response.text();
      })
      .then((response) => JSON.parse(response))
      .then((json) => this.setState({ links: json, loading: false }))
      .catch(() => this.setState({ loading: false }));
    setDocumentTitle(this.props.intl.formatMessage({ id: "meetings.title" }));
  }

  renderExperts() {
    const { links } = this.state;

    if (links && Object.keys(links).length > 0) {
      return Object.keys(links).map((key) => (
        <div key={key}>
          <br />
          {/*<i className="icon-user-follow"></i>&nbsp;&nbsp;*/}
          {/*<a href={links[key]['link']} style={{ fontSize: '16px' }} target="_blank" rel="noopener noreferrer">
                        <PersonName person={links[key]} useMiddleName isDoctor/>
                    </a>*/}
          <CustomButton
            url={links[key]["link"]}
            pageSettings={{}}
            prefill={{}}
            utm={{}}
            personName={formatPersonName({
              person: links[key],
              useMiddleName: true,
              isDoctor: true,
            })}
          />
          <br />
        </div>
      ));
    }

    return (
      <div className="portlet-body">
        <FormattedMessage id="meetings.not.found" />
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    return (
      <Layout>
        <div className="row">
          <div className="col-md-12">
            <Portlet as="main">
              <PortletTitle iconClassName="icon-calendar">
                <FormattedMessage id="meetings.title" />
              </PortletTitle>

              <div className="portlet-body">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <p
                      style={{ fontWeight: "900", fontSize: "16px", marginBottom: 5, marginTop: 1 }}
                    >
                      <FormattedMessage
                        id={
                          deployedUSA()
                            ? "meetings.choose.expert.predict"
                            : "meetings.choose.expert"
                        }
                      />
                    </p>
                    {this.renderExperts()}
                  </>
                )}
              </div>
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(MeetingsPage);

function CustomButton({
  url,
  prefill,
  pageSettings,
  utm,
  personName,
}: {
  url: string;
  personName: string;
}) {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return (
    <button style={buttonStyles} onClick={onClick}>
      {personName}
    </button>
  );
}
