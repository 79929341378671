import { useEffect } from "react";

export function useDocumentTitle(title: string): void {
  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
}

export function setDocumentTitle(title: string): void {
  document.title = `${WEBPACK_LABEL_TITLE} | ${title}`;
}
